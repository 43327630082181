<template>

<com-list class="resources" api="resources" :block="block" :tags="block.labels" v-on:loaded="onListLoaded">

	<template v-for="(item, index) in list">

		<com-card v-if="block.layout === $constants.resourceLayout.cards" :item="item" :key="index" />
		<com-table v-if="block.layout === $constants.resourceLayout.table" :item="item" :key="index" />
		<com-row v-if="block.layout === $constants.resourceLayout.list" :item="item" :key="index" />

	</template>

</com-list>

</template>

<script>

import mixBlock from './mixin.js'

export default {

	mixins: [mixBlock],

	components: {
		'com-list': () => import('./common/List'),
		'com-card': () => import('./resources/Card'),
		'com-table': () => import('./resources/Table'),
		'com-row': () => import('./resources/List')
	}

}

</script>

<style scoped>

</style>
