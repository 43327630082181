export default {

	props: ['block', 'page'],

	components: {
		'com-inner': () => import('./common/Inner')
	},

	data: function() {

		return {
			list: []
		}

	},

	methods: {

		onListLoaded: function(list) {

			this.list = list

		}

	}

}